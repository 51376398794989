import { Row, Col, Image, Container } from 'react-bootstrap';
import MediaQuery from 'react-responsive';


function NoseModal() {

    return (
        <div>
            <div className='modalIntro'>
                <h1>Falling Silent</h1>
                <br />
                <h4>"Falling Silent is a document of our time and a mirror of an artist's personal experiences. With the participation of some of Portugal's most renowned musicians, Lars Arens has created the ideal showcase for his compositions. With this unusual chamber ensemble, his stories are told with a lightness and fluidity as if they were a movie: A movie to listen to."
                </h4>
                <p>Carlos Bica, Double Bassist & Composer</p>
                <br />
                <MediaQuery maxWidth={1023}>
                <Container>
                    <Row>
                        <Col xs={18} md={12}>
                            <Image src={require('../musicians.png')} fluid />
                        </Col>
                    </Row>
                </Container>
                </MediaQuery>
                <MediaQuery maxWidth={3840} minWidth={1024}>
                <Container>
                    <Row>
                        <Col xs={4} md={3}></Col>
                        <Col xs={10} md={6}>
                            <Image src={require('../musicians.png')} fluid />
                        </Col>
                        <Col xs={4} md={3}></Col>
                    </Row>
                </Container>
                </MediaQuery>
                <br />
                <br />
                <h1>The Music</h1>
                <br />
                <h4>Møborg 2 plays ‘jazz chamber music’.
                    On its debut album, the group presents colourful ‘portrait compositions’ with reference to situations and events that have been important to me in recent years.
                    The aesthetics of the music, although recorded in times of increasing political, geopolitical and ecological uncertainty (the latter is also referred to in the piece “Falling Silent”), does not want to bow to this “zeitgeist” in terms of sound. It is not characterized by cacophony or “noise music”, but melodically and harmonically almost exclusively consonant.</h4>
                <br />
                <h1>About the name “MØBORG 2”</h1>
                <br />
                <h4>The name “Møborg 2” is a turnoff sign for the farming community Møborg in West Jutland/Denmark.
                    It is located close to a small farm where I spent many years on vacation with my family as a child. The place felt like a second home to me, in a childhood that was not overshadowed by climate change, wars, pandemics or other major problems. The landlord and his wife were like grandparents to us children.
                    “Møborg 2” from my perspective means association with fond memories of a secure time in a place that I still sometimes dream of...</h4>
                <p>2024 Lars Arens</p>
                <br />
            </div>
            <div className='modalIntro'>
                <h1>The Musicians</h1>
                <br />
            </div>
            <MediaQuery maxWidth={1023}>
            <Container>
                    <Row>
                        <Col xs={18} md={12}>
                        <Image className='musician-image' src={require('../lars.png')} fluid />
                        </Col>
                    </Row>
                </Container>
                
                <br />
                <br />
                <div className='modalIntro'>
                    <h1>Lars Arens </h1>
                    <h3>Composition, Trombone, Euphonium</h3>
                    <br />
                    <h3>Since he went directly to Lisbon after studying music in Hilversum and Amsterdam (1996-2000), the native of Münster was only briefly involved in the German jazz scene (first own groups, then BuJazzO, Summit Jazz Orchestra & Clark Terry and others).
                        Today he is teaching in the jazz department of Escola Superior de Música de Lisboa. (Lisbon College of Music). <br />
                        As a freelance musician, he appreciates the collaboration in various formations of the Portuguese jazz scene. In addition, he works with well-known bands from the Portuguese-speaking countries of Africa, such as “Tabanka Djaz” from Guinea-Bissau</h3>
                </div>
                <br />
                <br />
                <Container>
                    <Row>
                        <Col xs={18} md={12}>
                        <Image className='musician-image' src={require('../joao.png')} fluid />
                        </Col>
                    </Row>
                </Container>
                <br />
                <br />
                <div className='modalIntro'>
                    <h1>João Moreira </h1>
                    <h3>Trumpet, Flugel</h3>
                    <br />
                    <h3>João Moreira is a teacher at the jazz department at Escola Superior de Música de Lisboa and a well-known figure in the Portuguese jazz scene.
                        In his freelance work, he can look back on collaborations with many jazz greats, both nationally and internationally, with whom he has performed, including Mario Laginha, Maria João, Airto Moreira, Chris Cheek, Mark Turner and Carlos Bica.
                        For years he has also been touring with the internationally renowned Portuguese fado singer António Zambujo.</h3></div>
                <br />
                <br />
                <Container>
                    <Row>
                        <Col xs={18} md={12}>
                        <Image className='musician-image' src={require('../paulo.png')} fluid />
                        </Col>
                    </Row>
                </Container>
                <br />
                <br />
                <div className='modalIntro'>
                    <h1>Paulo Bernardino </h1>
                    <h3>Clarinet, Bass Clarinet</h3>
                    <br />
                    <h3>Paulo Bernardino belongs to the younger generation of Portuguese musicians.
                        As a very accomplished classical clarinetist (studied in Lisbon and Antwerp), he is both in demand as a classical soloist and active in various formations of Portuguese jazz musicians of the new generation, such as André B. Silva “MT. MERU” (Clean Feed Records) or Diogo Alexandre “Bock Ensemble” (JACC Records).
                    </h3></div>
                <br />
                <br />
                <Container>
                    <Row>
                        <Col xs={18} md={12}>
                        <Image className='musician-image' src={require('../mario.png')} fluid />
                        </Col>
                    </Row>
                </Container>
                
                <br />
                <br />
                <div className='modalIntro'>
                    <h1>Mário Delgado </h1>
                    <h3>Guitars</h3>
                    <br />
                    <h3>In addition to his lectureship in the jazz department of Escola Superior de Música de Lisboa, Mário Delgado is (such as João Moreira) one of the best-known representatives of an experienced generation of Portuguese jazz musicians, who also recorded many albums with his own projects, such as “TGB”, “Filacatera” and “Lokomotiv” (Clean Feed Records).
                        As a freelance musician performing music with large stylistic variety, he is not only in demand in the jazz scene, but also as a sideman and studio musician in productions of nationally known artists of the Portuguese pop and rock scene.</h3></div>
                <br />
                <br />
                <Container>
                    <Row>
                        <Col xs={18} md={12}>
                        <Image className='musician-image' src={require('../sara.png')} fluid />
                        </Col>
                    </Row>
                </Container>
                <br />
                <br />
                <div className='modalIntro'>
                    <h1>Sara Abreu </h1>
                    <h3>Cello</h3>
                    <br />
                    <h3>Sara Abreu is a young Portuguese cellist who, after studying classical music in Lisbon, works as a freelance musician in both classical and non-classical orchestras and ensembles. In addition, she also works as an instrumental teacher.</h3></div>
            </MediaQuery>
            <MediaQuery maxWidth={3840} minWidth={1024}>
                <Container content='container-contain'>
                    <Row>
                        <Col className='row-right' xs={9} md={6}><Image src={require('../lars.png')} fluid/></Col>
                        <Col className='row-left' xs={9} md={6}>
                            <h1>Lars Arens </h1>
                            <h3>Composition, Trombone, Euphonium</h3>
                            <br />
                            <h3>Since he went directly to Lisbon after studying music in Hilversum and Amsterdam (1996-2000), the native of Münster was only briefly involved in the German jazz scene (first own groups, then BuJazzO, Summit Jazz Orchestra & Clark Terry and others).
                                Today he is teaching in the jazz department of Escola Superior de Música de Lisboa. (Lisbon College of Music). <br />
                                As a freelance musician, he appreciates the collaboration in various formations of the Portuguese jazz scene. In addition, he works with well-known bands from the Portuguese-speaking countries of Africa, such as “Tabanka Djaz” from Guinea-Bissau</h3>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <br />
                    <Row>
                        <Col xs={9} md={6} className='row-right'>
                            <h1>João Moreira </h1>
                            <h3>Trumpet, Flugel</h3>
                            <br />
                            <h3>João Moreira is teacher and head of the jazz department at Escola Superior de Música de Lisboa and a well-known figure in the Portuguese jazz scene.
                                In his freelance work, he can look back on collaborations with many jazz greats, both nationally and internationally, with whom he has performed, including Mario Laginha, Maria João, Airto Moreira, Chris Cheek, Mark Turner and Carlos Bica.
                                For years he has also been touring with the internationally renowned Portuguese fado singer António Zambujo.</h3>
                        </Col>
                        <Col xs={9} md={6} className='row-left'><Image src={require('../joao.png')} fluid /></Col>
                    </Row>
                    <br />
                    <br />
                    <br />
                    <Row>
                        <Col xs={9} md={6} className='row-right'><Image src={require('../paulo.png')} fluid/></Col>
                        <Col xs={9} md={6} className='row-left'>
                            <h1>Paulo Bernardino </h1>
                            <h3>Clarinet, Bass Clarinet</h3>
                            <br />
                            <h3>Paulo Bernardino belongs to the younger generation of Portuguese musicians.
                                As a very accomplished classical clarinetist (studied in Lisbon and Antwerp), he is both in demand as a classical soloist and active in various formations of Portuguese jazz musicians of the new generation, such as André B. Silva “MT. MERU” (Clean Feed Records) or Diogo Alexandre “Bock Ensemble” (JACC Records).
                            </h3>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <br />
                    <Row>
                        <Col xs={9} md={6} className='row-right'>
                            <h1>Mário Delgado </h1>
                            <h3>Guitars</h3>
                            <br />
                            <h3>In addition to his lectureship in the jazz department of Escola Superior de Música de Lisboa, Mário Delgado is (such as João Moreira) one of the best-known representatives of an experienced generation of Portuguese jazz musicians, who also recorded many albums with his own projects, such as “TGB”, “Filacatera” and “Lokomotiv” (Clean Feed Records).
                                As a freelance musician performing music with large stylistic variety, he is not only in demand in the jazz scene, but also as a sideman and studio musician in productions of nationally known artists of the Portuguese pop and rock scene.</h3>
                        </Col>
                        <Col xs={9} md={6} className='row-left'><Image src={require('../mario.png')} fluid /></Col>
                    </Row>
                    <br />
                    <br />
                    <br />
                    <Row>
                        <Col xs={9} md={6} className='row-right'><Image src={require('../sara.png')} fluid/></Col>
                        <Col xs={9} md={6} className='row-left'>
                            <h1>Sara Abreu </h1>
                            <h3>Cello</h3>
                            <br />
                            <h3>Sara Abreu is a young Portuguese cellist who, after studying classical music in Lisbon, works as a freelance musician in both classical and non-classical orchestras and ensembles. In addition, she also works as an instrumental teacher.</h3>
                        </Col>
                    </Row>
                    <br />
                    <br />
                </Container>
            </MediaQuery>
        </div>
    )

}

export default NoseModal
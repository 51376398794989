
function EarsModal() {

    return(
        <div>
            <h1>Where to Listen to Falling Silent:</h1>
          <br />
          <h4>Digital Release on November 15th.</h4>
          {/*<Container>
            <Row>
              <Col><Button style={{backgroundImage:"url('./img/apple_music.png')", marginTop: '20px' ,backgroundColor: '#000000', border: '#000000', backgroundSize:"cover", width:"100px", height:"100px"}}></Button></Col>
              <Col><Button style={{backgroundImage:"url('./img/spotify.png')",marginTop: '20px',backgroundColor: '#000000', border: '#000000',backgroundSize:"cover", width:"100px", height:"100px"}}></Button></Col>
            </Row>
            <Row>
              <Col><h2>Apple Music</h2></Col>
              <Col><h2>Spotify</h2></Col>
            </Row>
          </Container>*/}
        </div>
    )
}

export default EarsModal
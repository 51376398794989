import { Button } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import ReactDOMServer from 'react-dom/server';
import { useState } from 'react';

function PrintTable(csvData) {
    var [arrayItems, setArrayItems] = useState([]);

     (csvData.data).then((promiseData) => {
        setArrayItems(promiseData.data);  
    })

    console.log(arrayItems)

    if(arrayItems.length !== 0)
    {
        return (
            <Table>
                <tbody>
                    { 
                        arrayItems.map((value, index) => {
                            
                            return (
                                <tr key={index}>
                                    <td><div id='rectangle'></div></td>
                                    {value.map((val, i) => {
                                        if(i === 3)  {
                                            if(val !== '') {
                                                return <td key={i}><a href={val} target='_blank'><Button variant='light'> Know More </Button></a></td>
                                            } else {
                                                return <td key={i}></td>;
                                            }
                                        } else {
                                            return <td key={i}>{val}</td>
                                        }
                                    })}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        );
    } else {
        return (
            <h3>No Upcoming Shows</h3>
        )
    }
}

export default PrintTable;
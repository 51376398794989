import { Table } from "react-bootstrap";
import PrintTable from "./EyesTable";
import { useState } from "react";

function EyesModal(csvData) {

    return (
        <div>
        <h1>Upcoming Shows</h1>
          <br />
            <PrintTable data={csvData.data}/>
        </div>
    );
}

export default EyesModal;